import React, { useState } from "react";
import PropTypes from "prop-types";
import { EN } from "./en";
import { DE } from "./de";
import { isBrowser } from "../utils/general";

export const LanguageContext = React.createContext();

const LanguageProvider = ({ children }) => {
	const getDefaultLang = () => {
		if (isBrowser()) {
			let p = window.location.pathname.split("/");
			if (p[1] && p[1] === "en") return "EN";
			return "DE";
		}
		return "DE";
	};

	const getDefaultTranslater = () => {
		if (isBrowser()) {
			let p = window.location.pathname.split("/");
			if (p[1] && p[1] === "en") return EN;
			return DE;
		}
		return DE;
	};

	const [state, setState] = useState({
		language: getDefaultLang(),
		translations: getDefaultTranslater(),
	});

	const changeLanguage = (language) => {
		setState({ language, translations: language === "DE" ? DE : EN });
	};

	return (
		<LanguageContext.Provider
			value={{
				...state,
				changeLanguage,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

LanguageProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
export default LanguageProvider;
