export const DE = {
  // common: {
  //     phonePlaceholder: "Enter Phone Number"
  // },

	navbar: {
		airportText: "Flughafentransport",
		aboutUsText: "Über uns",
		myAccountText: "My Account",
		contact: "Kontakt",
		taxiEindhoven: "Taxi Neumünster",
		taxiRate: "Taxipreise",
		airportTransportation: "Flughafentransport",
		signoutText: "Sign Out",
		loginText: "Einloggen",
	},
  hotelDashboard: {
		createRide: {
			getTaxiText: "Neue Fahrt erstellen",
			selectTaxi: "Wählen Sie Taxi",
			customerInfo: "Kundeninformation",
			para: "Wenn Sie keine Fahrten über das Dashboard buchen können",
			option1:"Buchen Sie eine Fahrt telefonisch unter 0402405999",
			option2: "Oder rufen Sie +31624509009 an, wenn Sie technische Fragen haben",
      pickUp:"Von",
      drop:"Zu",
      notesText:"Anmerkungen",
      dateTimeText:"Datum/Zeit",
      bookText:"Buch",
      placeSelect:"Wählen Sie Ort",
      typeText:"Geben Sie hier ein"
		},
    subHeader:{
      currentRides:"Aktuelle Fahrten",
      pastRides:"Vergangene Fahrten",
      excelExport:"Nach Excel exportieren",
      sortBy:"Nach Datum sortieren",
      ascText:"Aufsteigende Reihenfolge",
      desText:"Absteigende Reihenfolge"
    },
    tableHeader:{
      passanger:"Passagier",
      ride:"Reiten",
      driver:"Treiber",
      name:"Name",
      phone:"Telefon",
      room:"Zimmer",
      status:"Status",
      arrivingIn:"Ankunft in",
      price:"Preis",
      commision:"Kommission",
      track:"Spur",
      changeDateTime:"Datum / Uhrzeit ändern",
      cancel:"Cancel",
      car:"Car"
    },
    tabelRow:{
      noRows:"Keine Fahrten gefunden"
    }

	},

  hotelLoginText:{
    emailError:"Bitte füllen Sie die E-Mail",
    passwordError:"Bitte geben Sie das Passwort ein",
    validEmail:"Bitte füllen Sie eine gültige E-Mail aus",
    passwordText:"Passwort",
    passwordPlaceholder:"passwort",
    rememberText:"Mich erinnern",
    headerText:"Schnelleintaxi Geschäft",
    loginText:"Melden Sie sich mit den von Schnelleintaxi bereitgestellten Anmeldedaten an. Haben Sie Ihre Anmeldedaten vergessen oder verloren? Dann kontaktieren Sie uns telefonisch unter +31402405999"
  },

	privacyPolicy: {
		culture: "de",
	},
	covid: {
		para1:"Bei Schnelleintaxi möchten wir unseren Kunden in diesen schwierigen Zeiten eine sichere und günstige Fahrt ermöglichen. Um Ihre Sicherheit und die Sicherheit unserer Fahrer zu gewährleisten, haben wir Maßnahmen getroffen.",
    	listItem1:"Alle unsere Taxis haben einen Sicherheitsbildschirm zwischen Fahrer und Beifahrer, um die Sicherheit zwischen ihnen zu gewährleisten.",
    	listItem2:"Alle unsere Fahrer haben eine nicht-chirurgische Maske. Bitte scheuen Sie sich nicht, den Fahrer zu bitten, die Maske aufzusetzen, schließlich ist dies Ihre Sicherheit (falls der Fahrer keine trägt).",
		listItem3:"Auf dem Rücksitz unserer Taxis finden Sie ein Händedesinfektionsmittel. Sie können es beliebig oft kostenlos verwenden.",
		listItem4:"Unsere Fahrer sind mit Desinfektionsmitteln und Tüchern ausgestattet, um Hände und Kontaktstellen zu reinigen.",
		listItem5:"Während jeder Fahrt und zwischen den Fahrten wird das Fahrzeug so gut wie möglich belüftet.",
		listItem6:"Die Kontaktpunkte im Fahrzeug (Türgriff, Armlehne, Sicherheitsgurt, Stiftausrüstung und Griff) werden extra gereinigt.",
		para2:"Wir bitten unsere Passagiere in dieser schwierigen Zeit, Folgendes zu beachten:",
		listItem7:"Steigen Sie selbst in das Taxi ein und aus.",
		listItem8:"Wenn Sie alleine sind, setzen Sie sich hinten rechts.",
		listItem9:"Legen Sie den Sicherheitsgurt an.",
		listItem10:"Wenn möglich, legen Sie Ihr Gepäck in den Kofferraum oder halten Sie es auf Ihrem Schoß. indien mogelijk bagage in de kofferbak of houd deze op schoot.",
		listItem11:"Zahlen Sie digital oder mit PIN / Kreditkarte. Vermeiden Sie Barzahlungen.",
		listItem12:"Die Anzahl der Passagiere pro Fahrzeug ist unbegrenzt. Alle Sitze dürfen benutzt werden.",
		listItem13:"Wenn mehrere Passagiere befördert werden und zwischen diesen Passagieren keine 1,5 Meter erkennbar sind, bitten wir sie, eine Maske zu verwenden.",
		para3:"Um die Sicherheit aller zu gewährleisten, fragen wir unsere Kunden, die Symptome von COVID-19 mögen",
		listItem14:"Colds such as nose cold, runny nose, sneezing, sore throat",
		listItem15: "Husten",
		listItem16:"Ärger",
		listItem17: "Zunahme oder Fieber",
		listItem18:"Plötzlicher Geruchs- und / oder Geschmacksverlust (ohne verstopfte Nase)",
		para: "Zu Hause bleiben!",
		para4:"If you have one or more symptoms that match an infection with COVID-19, you can be tested.",
		heading1: "Leichte Beschwerden",
		headingPara1:"Erkältungssymptome, Erkältung der Nase, laufende Nase, Niesen, Halsschmerzen, Husten, Zunahme oder wenn Sie plötzlich nicht mehr richtig riechen oder schmecken. Lassen Sie sich testen und bleiben Sie zu Hause, bis die Ergebnisse bekannt sind. Schauen Sie sich Rijksoverheid.nl an, um einen Termin für einen Test zu vereinbaren.",
		heading2: "Schwerwiegende Symptome",
		headingPara2:"Engegefühl und / oder Fieber (über 38 Grad) oder wenn Sie sich kranker und kranker fühlen. Rufen Sie sofort den Arzt oder die GP-Post an.",
		para5: "Maßnahmen zur Verhinderung der Ausbreitung",
		listItem19:"Waschen Sie Ihre Hände oft mit Wasser und Seife und trocknen Sie sie dann gut ab.",
		listItem20: "Husten und Niesen in der Innenseite Ihres Ellbogens.",
		listItem21:"Verwenden Sie Papiertaschentücher, um sich die Nase zu putzen, und werfen Sie sie dann weg.",
		listItem22: "Schütteln Sie nicht die Hand.",
		listItem23: "Halten Sie 1,5 Meter (2 Armlängen) von anderen entfernt.",
	},
	home: {
		routeSelection: {
			pickUpPlaceHolder: "Von Wo",
			noPlaceHolder: "Hnr.",
			noOfPassengersText: "Anzahl der Passagiere",
			destinationPlaceHolder: "Wohin",
			onewayText: "One Way",
			returnDateTimeText: "Wählen Sie Datum und Uhrzeit der Rückgabe",
			returnText: "Rückkehr",
			returnPlaceholder: "Return Date",
			nextButtonText: "NEXT",
			nextOneText: "nächster",
		},
		dateTimePicker: {
			chooseDateTimeHeading: "Wählen Sie Datum und Uhrzeit",
			nowTwentyMinText: "jetzt in 10 Minuten",
			todayText: "heute",
			tommorowText: "Morgen",
			customText: "Anpassung",
			nowText: "Jetzt",
			culture: "de",
			selectText: "wählen",
			closeText: "zu löschen",
		},
		heroSection: {
			getTaxiText: "Buchen Sie Ihr Taxi",
			whereToGoText: "Where do you want to go?",
			welcomeText: "Willkommen zu",
			taxiText: "Schnelleintaxi",
			selectTaxi: "Taxi auswählen",
			about: {
				ourCompanyText: `ABOUT`,
				aboutText: `US`,
			},
		},
		ourPartners: {
			mainHeading: "Unsere Partner"
		},
		benefits: {
			benefit1: "Track-Fahrer",
			benefit2: "Teilen Sie den Tracking-Link",
			benefit3: "Kostenlose Stornierung und Änderungen",
			benefit4: "Corona Beweis",
			mainHeading1: "Zuverlässig",
			mainHeading2: "Sicher",
			mainHeading3: "Steuerung",
			mainHeading4: "Corona Beweis",
			para1: "Mit unserem Buchungssystem können Sie Ihren Fahrer über einen per E-Mail bereitgestellten Tracking-Link verfolgen. Sie kennen immer den Standort des Fahrers und können ihn bei Bedarf kontaktieren.",
			para2: "Informationen zu Ihren Fahrern finden Sie im Tracking-Link und in der E-Mail. Wie Name, Telefonnummer und Fahrzeugdetails. Teilen Sie Ihren Tracking-Link mit Ihren Freunden.",
			para3: "Sie können Ihre Fahrt bis 1 Stunde vor Abholzeit stornieren oder ändern.",
			para4: "Alle unsere Taxis haben einen Sicherheitsbildschirm zwischen Beifahrer und Fahrer. Unsere Fahrer haben eine Maske und diese wird auch den Passagieren empfohlen"
		},
		covidBanner: {
			para:"Ihre Sicherheit ist für uns von größter Bedeutung! Lesen Sie mehr über unsere Vorsichtsmaßnahmen bezüglich der Nutzung von Taxis während COVID-19.",
			linkText: "Weiterlesen",
			culture: "de",
		},
		howItWorks: {
			processText: "Was ist der Prozess?",
			mainHeading: "Wie funktioniert es",
			mainPara:
				"Unser Ziel ist es, Passagiere so einfach, schnell und günstig wie möglich von A nach B zu bringen. Dies können wir erreichen, indem wir professionelle Fahrer einsetzen. Sie können uns mit Uber vergleichen, aber mit der Sicherheit eines erfahrenen Fahrers mit viel Fahrerfahrung und einem gut regulierten System. Wenn Sie ein Taxi bei Schnelleintaxi bestellen, gehen die Informationen an einen unserer verbundenen Fahrer, der dem Abholort am nächsten liegt. Wenn unser Fahrer die Fahrt akzeptiert, werden Sie sofort sehen, wer sie auf dem Bildschirm akzeptiert hat, welche Art von Auto der Fahrer hat und was seine Telefonnummer ist. Auf diese Weise versuchen wir, Ihnen eine optimale Reise zu",
			subSections: {
				firstSection: {
					heading: "Geben Sie Ihre Reiseinformationen ein",
					para:
						"Geben Sie zunächst Ihre Abholung und Endadresse mit der Hausnummer ein. Dann wählen Sie, mit welchem Auto Sie transportiert werden möchten. Dann wählen Sie, zu welchem Zeitpunkt Sie das Taxi haben möchten (innerhalb von jetzt und 20 Minuten oder später). Und schließlich füllen Sie Ihre persönlichen Daten aus.",
				},
				secondSection: {
					heading: "Sehen Sie, wer Ihre Fahrt akzeptiert hat",
					para:
						"Wenn Sie Ihre persönlichen Daten eingeben und auf ''Einen Fahrer suchen'' klicken und unser System nach dem nächstgelegenen Fahrer sucht. Wenn dann einer unserer Fahrer die Fahrt akzeptiert, können Sie sehen, welcher Fahrer die Fahrt angenommen hat, mit welchem Auto Sie transportieren, welche Farbe das Auto hat und was das Kennzeichen des Autos ist. So wissen Sie immer mit Sicherheit, dass Sie in das richtige Auto steigen!",
				},
				thirdSection: {
					heading: "Genießen Sie Ihre Fahrt",
					para:
						"Wenn der Fahrer eine Fahrt akzeptiert, können Sie zur Zahlung übergehen. Wenn die Zahlung erfolgreich war, erhalten Sie und der Fahrer eine Benachrichtigung, dass die Zahlung erfolgreich war. Alles, was Sie erwarten, ist Ihre Fahrt zu genießen!",
				},
			},
		},

    ourCars: {
      mainHeading: "Unsere Preise",
      paymentHeading: "Zahlungsmethoden",
      cars: {
        firstCar: {
          name: "Tagsüber",
          startPrice: "Grundgebühr : ",
          farePerKm1: "Kilometer 1 - 2 : ",
          farePerKm2: "Kilometer 3 - 6 : ",
          farePerKm3: "Jeder weitere Kilometer : ",
          farePerKm4: "Stand-/Wartezeitro Stunde : ",
        },
        secondCar: {
          name: "Nachts",
          dsc:
            "Montag bis Samstag von 23:00 Uhr abends bis 6:00 Uhrs morgens. Sonntag ganztägig.",
          startPrice: "Grundgebühr : ",
          farePerKm1: "Kilometer 1 - 2 : ",
          farePerKm2: "Kilometer 3 - 5 : ",
          farePerKm3: "Jeder weitere Kilometer : ",
          farePerKm4: "Stand-/Wartezeitro Stunde : ",
        },
      },
    },

    paymentMethods: {
      mainHeading: "Zahlungsmethoden",
      para:
        "Schnelleintaxi macht es einfach, online zu bezahlen, zu bezahlen, wie Sie es am besten mögen. Bezahlen Sie per GiroPay, Apple pay, PayPal, Kreditkarte oder bar im Taxi bezahlen. Sie können auch mit Ihrem Card im Taxi bezahlen. Sie erhalten immer eine Rechnung/Bestätigung Ihrer Zahlung auf Ihrer Post, Sie können auch immer nach der Fahrt im Taxi selbst eine Quittung anfordern. Sie können auch nachträglich per Rechnung bezahlen. Bitte beachten Sie, dass die Zahlung nach der Zahlung nur für Geschäftskunden oder Kunden möglich ist, die ein Konto bei Schnelleintaxi haben.",
    },

    fixedPrices: {
      mainHeading: "Unsere Festpreise",
      plans: {
        firstPlan: {
          name: "Neumünster-Hamburg",
          price: "CA. 90 €",
          noOfPassengers: "3 passengers",
        },
        secondPlan: {
          name: "Neumünster-Flughafen Hamburg",
          price: "CA. 85 €",
          noOfPassengers: "2 passengers",
        },
        thirdPlan: {
          name: "Neumünster-Kiel",
          price: "CA. 50 €",
          noOfPassengers: "5 passengers",
        },
        fourthPlan: {
          name: "Kiel-Hamburg",
          price: "CA. 135 €",
        },
        fifthPlan: {
          name: "Kiel-Flughafen Hamburg",
          price: "CA. 125 €",
        },
      },
    },

    taxionspot: {
      mainHeading: "SCHNELLEINTAXI",
      // subHeading:
      //   "Bestellung ein Taxi über Schnelleintaxi garantiert Folgendes: ",
      subHeading: "Immer unterwegs für Sie!",
      subHeading2: "Ihre Sicherheit zählt!",
      para1:
        "Schnelleintaxi ist flexibel und schnell, wir sind in Kiel, Neumünster und Hamburg einsatzbereit. Mit m 80-Fahrer und seinen Luxusautos bringen wir Sie einfach, sicher und bequem von A nach B.",
      para2:
        "Bei Schnelleintaxi sehen wir unsere Kunden nicht als Kunden, sondern als unsere Gäste, wenn Sie einmal in unser Taxi steigen, werden Sie als unser Gast behandelt. Wenn Sie sich für schnellintaxi entscheiden, garantieren wir Ihnen.",
      listItem1: "Ausgezeichneter Service",
      listItem2: "24/7 Verfügbarkeit",
      listItem3: "Englischsprachige Fahrer müssen",
      listItem4: "Ordentliche saubere Autos",
      listItem5: "Ordentliche und gepflegte Fahrer",
      listItem6: "Schutz vor COVID-19",
      safetyPara:
        "Wer ein Taxi bestellt, kann verschiedene Wünsche äußern. Um die Ausbreitung des Coronavirus einzudämmen, kann jetzt auch ein Taxi mit Schutzwand bestellt werden. Tag und Nacht. Unsere Disposition nimmt Ihre Wünsche gerne entgegen.",
      btnText: "Read More",
    },
    wantToKnowMore: {
      subHeading: "Haben Sie eine Frage?",
      mainHeading: "Möchten Sie mehr wissen?",
      para: `Schnelleintaxi ist ein transparentes und serviceorientiertes Unternehmen, wir sind immer für alle Ihre Fragen bereit. Deshalb können Sie uns auf unterschiedliche Weise erreichen! `,
      contacts: {
        office: {
          name: "Unser Büro",
          para: "Houckgeeststraat 13 Eindhoven",
        },
        phone: {
          name: "Via Telefoon",
          para: "+49 4321 7570460",
        },
        email: {
          name: "Of Via Mail",
          para: "service@schnelleintaxi.de",
        },
      },
    },

    testimonials: {
      mainHeading: "Testimonials",
      subHeading: "What our customers think of us",
    },
  },
  personalInfo: {
    formTitle: "Persönliche Informationen",
    firstNameRequired: "Vorname Required",
    lastNameRequired: "Nachname Required",
    emailRequired: "Email Required",
    phoneRequired: "Phone Required",
    journeyTypeRequired: "Journey type required",
    firstNamePlaceHolder: "Vorname",
    lastNamePlaceHolder: "Nachname",
    emailPlaceHolder: "E-Mail-Addresse",
    phonePlaceHolder: "Enter Your Phone",
    houseNoRequired: "House No Required",
    houseNoPlaceHolder: "House No",
    journeyTypePlaceHolder: "Type of Journey",
    returnPlaceholder: "Return",
    oneWayPlaceholder: "One Way",
    buttonText: "nächster",
    paymentTypeText: `Payment Type`,
    cashText: `Cash`,
    onlineText: `Online Payment`,
    notes: "Anmerkungen",
		textareaPlaceholder: "Flugnummer, Kommentar etc...",
  },
  about: {
    ourCompany: {
      headingText: "Unser Unternehmen",
      paraText: `Unser Traum ist es, Passagiere so einfach, schnell und kostengünstig wie möglich von A nach
        B zu bringen. Dies können wir erreichen, indem wir Berufskraftfahrer beschäftigen. Sie
        können uns mit Uber vergleichen, aber mit derSicherheit eines erfahrenen Fahrers mitviel
        Fahrerfahrung und einem gut regulierten System. Wenn Sie ein Taxi bei Schnelleintaxi
        bestellen, gehen die Informationen an einen unserer angeschlossenen Fahrer, der in der Nähedes
        Abholortes liegt. Wenn unser Fahrer dieFahrtakzeptiert, werden Sie sofort auf dem
        Bildschirm sehen, wer sie angenommen hat, welche Art von Auto das Auto hat und was ihre
        Telefonnummer ist. Sie erhalten auchsofort bevestiging eine Textnachricht. gelijk een sms te
        Es gibt einen Linkzu der Textnachricht , dies ermöglicht es Ihnen, Ihrem Fahrer zu folgen
        oder Ihre Reise zu stornieren. Auf diese Weise versuchen wir Ihnen ein u optimale trip belfzu
        geben.`,
    },
    whyTaxiOnSpot: {
      headingText: "Warum Schnelleintaxi?",
      paraText1: `Die Bestellung eines Taxis über Schnelleintaxi garantiert Folgendes:`,
      bullet1: `Ein professioneller u Fahrer, der immer für Sie da ist`,
      bullet2: `Ein ausgezeichneter 24/7 Service, der zu jeder Tageszeit zu erreichen ist`,
      bullet3: `Ein unglaublich niedriger Satz, mit manchmal Rabatten von bis zu 60%`,
      paraText2: `Es ist möglich, uns für den regelmäßigen Transport zu beauftragen, aber es ist auch möglich,
      ein Taxi für alle Ihre Reisen zum und vom Flughafen zu bestellen. Wir sorgen dafür, dass Sie
      rechtzeitig für Ihren Flug sind. Darüber hinaus helfen Ihnen unsere hilfsbereiten Fahrer immer
      beim Laden Ihres Gepäcks.`,
      paraText3: `Qualität, Komfort und exzellenter Service zu einem niedrigen Preis. Dafür stehen wir bei
      Schnelleintaxi. Vereinbaren Sie noch heute einen Termin oder buchen Sie ein Taxi, bevor Sie
      es wissen, sind Sie bereits am Zielort!`,
    },
  },
  confirmBooking: {
    reviewBookingText: "Buchung anzeigen",
    outwardText: "Reiten",
    numOfTravelersText: "Anzahl der Passagiere",
    contactDetailsText: "Ihre Kontaktdaten",
    totalText: "Gesamt",
    btnText: "buchen Sie jetzt",
    paymentTypeText: `Payment Type`,
    applyCoupon: `Rabattcode hinzufügen`,
  },
  rideAccepted: {
    reviewBookingText: "Buchung anzeigen",
    outwardText: "Reiten",
    numOfTravelersText: "Anzahl der Passagiere",
    contactDetailsText: "Ihre Kontaktdaten",
    totalText: "Gesamt",
    btnText: "Zahlen",
    taxiDetailText: "Taxi-Details",
    driverDetailsText: "Details Ihres Fahrers",
    modelText: "Modell",
    licenceText: "Kennzeichen",
    colorText: "Farbe",
    nameText: "Namen",
    phoneText: "Telefon",
    taxi: "Taxi-Details",
  },
  payment: {
    outwardText: "Hinfahrt",
    mollieText: "Mollie Zahlung",
    molliePara: `Sichere Überweisung über Ihr Bankkonto. Visum, Maestro, Entdecken Sie American Express.`,
    btnText: "Zahlen",
    payCash: "Zahlen Sie bar im Taxi oder mit Karte im Taxi",
    discountText: "Zahlen Sie online und erhalten Sie * % zusätzlichen Rabatt",
    paymentMethodText: "Wählen Sie die Zahlungsmethode",
    termNote: "Durch die zwingende Zahlung stimmen Sie unseren zu",
    termsAndConditions: " GeschäftsbedingungenInstead",
    cashPay: "Barzahlung",
    creditCard: "Kreditkarte",
    paySecure: "Zahlen Sie sicher",
    paymentHeading: "Zahlung",
    verify: "Überprüfen",
    phone: "Telefonnummer",
    goback: "Geh zurück",
    resend: "Code erneut senden",
    verifyPhone: "Überprüfen Sie die Telefonnummer",
  },
  resetPassword: {
    heading: "Neues Passwort eingeben",
    password: "Passwort eingeben",
    repeatPassword: "Passwort wiederholen",
    btn: "Nächster",
  },
  signup: {
    heading: "Ein Konto erstellen",
    name: "Name",
    email: "Email",
    phone: "Telefonnummer",
    password: "Passwort",
    repeatPassword: "Passwort wiederholen",
    agree:
      "Ich stimme den allgemeinen Geschäftsbedingungen und den Datenschutzbestimmungen zu (link to our genral conditions and privecy policy)",
    btn: "Registrieren",
    alreadyMember: "Bist du schon Mitglied?",
  },
  login: {
    rememberText: "Behalte mich in Erinnerung",
    forgotText: "Passwort vergessen?",
    dontHaveText: `Sie haben noch keinen Account?`,
    btnText: "Anmeldung",
    emailVerifyHeading: "E-Mail-Verifizierung",
    emailVerifyMsg:
      "Geben Sie eine gültige E-Mail-Adresse ein, um das Passwort zurückzusetzen",
    emailVerifyPlaceholder: "Email eingeben",
    emailVerifyBtn: "Nächster",
  },
  selectCar: {
    mostPopular: "Most Popular",
    numOfPassengersText: "Number of passengers",
    nowText: "Now",
    btnText: "Wählen",
    totalText: "Total",
    bigVanText: "Big Van",
    forText: "For",
    personText: "Person",
    notFound: "No Taxi Found",
  },
  bookingTime: {
    rightNowText: "I need a taxi right now",
    ridelateText: "I need a taxi later",
    rightNowSubText: "Book a Taxi within 20 Minutes",
    rideLaterSubText: "Plan your Taxi Ride",
    btnText: "Next",
  },
  myAccount: {
    myAccountText: "Mein Konto",
    accDetailsText: "Kontoinformationen",
    accDetailsSubText: "Ändern Sie die Details Ihres Kontos unten",
    contactDetails: "Kontaktdetails",
    nameText: "Name",
    emailtext: "Email",
    phoneText: "Telefonnummer",
    passwordText: "Passwort",
    deleteText: "Konto entfernen",
    deleteSubText: "Löschen Sie Ihr Konto von unseren Servern",
    deleteBtnText: "Konto löschen",
    changeBtnText: "Veränderung",
    myBookingText: "Meine Buchungen",
  },
  myBooking: {
    myBookingText: "Meine Buchungen",
    myAccountText: "Mein Konto",
    bookingNumberText: "Buchungsnummer",
    outwardText: "Outward Ride",
    returnRideText: "Hin-und Rückfahrt ",
    passengersText: "Passagiere",
    statusText: "Status",
    actionText: "Action",
    detailBtnText: "Einzelheiten",
    missedBtnText: "missed",
    scheduledBtnText: "scheduled",
    completedBtnText: "completed",
    cancelText: "Stornieren",
  },
  rideDetails: {
    bookingNumberText: "Buchungsnummer ",
    outwardText: "Outward ride",
    driverDetailsText: "Treiberdetails",
    taxiDetailText: "Taxidetails",
    numOfPassengersText: "Anzahl der Passagiere",
    cancelBtnText: "Cancel Ride",
    totalText: "Gesamt",
    tripDateText: "Reise datum",
    tripTimeText: "Reisezeit",
    modelText: "Model",
    licenceText: "Nummernschild",
    colorText: "Farbe",
    nameText: "Name",
    phoneText: "Telefon",
    passengerText: "Passengers",
  },
  thankyou: {
    thankYouText:
      "Vielen Dank für Die Buchung bei Schnelleintaxi, melden Sie sich an, um Ihre Fahrten zu sehen und Änderungen vorzunehmen. Oder überprüfen Sie Ihre E-Mail für Ihre aktuellen Fahrtdetails.",
    buttonText: "Zurück zur Seite",
  },
  thank_you: {
    thank_YouText:
      "Vielen Dank für Ihre Kontaktaufnahme mit Schnelleintaxi. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
    buttonText: "Zurück zur Seite",
    trackText: "Verfolgen Sie Ihren Fahrer über diesen Link!",
  },
  bookingMenu: {
    locationText: "Location",
    bookingDetail: "Booking Details",
    waitingText: " Waiting",
    doneText: "Done",
  },
  rideWaiting: {
    text: `Bitte warten Sie einen Moment, wir suchen einen Fahrer für Sie. Vielen Dank für Ihre Geduld.`,
  },
  notFound: {
    text:
      "Unfortunately There Is No Driver Available At The Moment. Try Again In A Few Minutes.Or Call Our Central Office Directly",
    tryAgainButton: "Try Again",
    callButton: "Call",
  },
  blog: {
    culture: "de",
  },
  contactUs: {
    contactUsText: {
      contact: "KONTAKTIERE",
      us: "UNS",
    },
    heading: "Oder senden Sie uns eine Nachricht",
    name: "Name",
    email: "Email",
    subject: "Gegenstand",
    message: "Botschaft",
    send: "Senden",
    nameRequired: "Name Erforderlich",
    emailRequired: "Email Erforderlich",
    subjectRequired: "Gegenstand Erforderlich",
    messageRequired: "Botschaft Erforderlich",
  },
  taxiToEindhoven: {
    ourCompany: {
      headingText: "Our Company",
      paraText:
        "Our dream is to get passengers from A to B as easily, quickly and cheaply as possible. We can achieve this by employing professional drivers. Well you can compare us with Uber, but then with the confidence and professionalism of classic taxis. If you order a taxi at Schnelleintaxi, the information goes to one of our connected drivers who is close to the pick-up location. If our driver accepts the ride, you will immediately see on the screen who has accepted it, what kind of car the person has and what his telephone number is. You will also receive an SMS confirmation, in the SMS you will also receive a link with which you can follow the driver, call and cancel your journey. In this way we try to ensure that our passengers continue to have an optimal journey.",
    },
    hero: {
      taxi: "Taxi nach",
      city: "Neumünster",
    },
    whyTaxiOnSpot: {
      preHeadingText: "Eine Frage haben?",
      headingText: "Warum bei Schnelleintaxi buchen?",
      paraText1: `Die Bestellung eines Taxis über Schnelleintaxi garantiert Folgendes:`,
      bullet1: `Ein professioneller Fahrer, der immer für Sie da ist`,
      bullet2: `Ein ausgezeichneter 24/7 Service, der zu jeder Tageszeit zu erreichen ist`,
      bullet3: `Eine unglaublich niedrige Rate, manchmal Rabatte von bis zu 50%.`,
      paraText2: `Es ist möglich, uns für den regelmäßigen Transport anzurufen, aber es ist auch möglich, ein Taxi für
      alle Ihre Reisen zum und vom Flughafen zu bestellen. Durch die Taxis von Schnelleintaxi kommen Sie
      immer pünktlich zum Gate. Darüber hinaus helfen Ihnen unsere erfahrenen und spezialisierten
      Fahrer immer beim Beladen des Gepäcks.`,
      paraText3: `Qualität, Komfort, exzellenter Service zu einem niedrigen Preis. Dafür stehen wir bei Schnelleintaxi.
      Vereinbaren Sie noch heute einen Termin oder buchen Sie ein Taxi, bevor Sie es wissen, sind Sie
      bereits am Zielort!`,
      secondHeading: `Was kostet ein Taxi nach Neumünster?`,
      secondPara: `Die Kosten für eine Taxifahrt hängen von: dem Startpreis, der Entfernung und der Dauer der Reise.
      Dieser Tarif verhindert, dass Sie als Kunde zu viel bezahlen müssen. Dieser Höchstsatz wird von der
      Regierung festgelegt. Ein Taxiunternehmen darf nicht höher fahren als der Höchstfahrpreis. Wenn Sie
      Ihr Taxi über die Website buchen, wird automatisch bekannt gegeben, wie hoch die Kosten für die
      Taxifahrt sein werden. Sie geben Ihre genaue Adresse ein, mit der unsere Website einen genauen
      Preis für Sie berechnet. Dieser Preis hängt natürlich von Ihrem genauen Standort ab. Je länger die
      Entfernung, desto mehr Rabatt erhalten Sie, Rabatte können so hoch wie 50% sein. Wenn es eine
      kurze Strecke ist, zahlen Sie nie mehr als den maximalen Satz. Möchten Sie mehr über Taxitarife
      erfahren? Werfen Sie einen Blick auf unsere Seite! https://www. Schnelleintaxi.nl/taxi-preise `,
      buttonText: `Buchen Sie Ihr Taxi`,
    },
    costEstimate: {
      headingFirstPart: `Wie kann ich ein Taxi nach`,
      headingSecondPart: `Neumünster buchen?`,
      paraText: `Bei uns können Sie ganz einfach ein Taxi zum Flughafen Neumünster buchen.`,
      firstPoint: `Schritt 1: Klicken Sie hier, um Ihren Tarif zu berechnen`,
      secondPoint: `Schritt 2: Geben Sie die Adresse an, von der aus Sie zum Flughafen Neumünster reisen
      möchten.`,
      thirdPoint: `Schritt 3: Wählen Sie das Fahrzeug, das am besten zu Ihnen passt?`,
      secondPara: `Reisen mit max. 4 Männer nach Neumünster und wenig Gepäck dann ist es am besten, ein Standard-
      Auto zu wählen! Wenn Sie mit 8 Personen nach Neumünster reisen, wählen Sie einen Minivan!`,
      fourthPoint: `Schritt 4: Füllen Sie Ihre persönlichen Daten aus und bezahlen Sie sicher online.`,
      fifthPoint: `Schritt 5: Genießen Sie eine angenehme und angenehme Fahrt.`,
      buttonText: `Buchen Sie Ihr Taxi`,
      generalHeading: "Allgemeine Informationen Neumünster",
      generalText: `Neumünster ist eine kreisfreie Stadt in Schleswig-Holstein. Die Stadt
      hat 79.487 Einwohner auf einer Fläche von 71,63 km2. Neumünster ist
      nach Kiel, Lübeck und Flensburg die viertgrößte Stadt des Landes.`,
    },
  },
  airportTransport: {
    hero: {
      airport: `Flughafen`,
      transport: "verkehr",
    },
    easyBooking: {
      heading: `Buchen Sie einfach und online über Schnelleintaxi`,
      paragraph: `Bei Schnelleintaxi können Sie einfach und günstig ein Taxi zu allen Flughäfen in Deutschland, den
      Niederlanden und Belgien buchen. Es spielt keine Rolle, wo Sie ein Taxi bestellen. Unser
      automatisches Dispatcher-System stellt sicher, dass einem unserer entladenen Fahrer, der der
      Adresse am nächsten ist, die Fahrt zugewiesen wird. Dies stellt sicher, dass Sie immer noch ein Taxi 5
      Minuten im Voraus bestellen können, aber trotzdem stressfrei, sicher und natürlich eine günstige
      Fahrt genießen können. Der Preis einer Fahrt hängt von der Entfernung und Dauer der Fahrt ab, aber
      im Allgemeinen sind die Fahrten zu einem Flughafen oft billiger als eine Fahrt anderswo mit der
      gleichen Entfernung. Wenn Sie wissen möchten, dass Ihre Fahrt Kosten verursacht, klicken Sie auf
      den Button &quot;Break your fare&quot; und sehen Sie, was der Preis ist.`,
      buttonText: `Buchen Sie Ihr Taxi`,
    },
    germanAirports: {
      heading: `Und Belgien`,
    },
    netherlandAirports: {
      cardtitle1: `Unser beliebtester Flughafen in Deutschland Flughafen Hamburg ist natürlich Schiphol, aber
      außerdem transportieren wir Passagiere auch fast täglich nach:`,
      cardTitle2: `Neben Deutschland transportieren wir auch Passagiere zu Flughäfen in den Niederlanden:`,
    },
  },
  driverDetail: {
    culture: "de",
  },
  taxiRates: {
    hero: {
      taxi: `Taxi`,
      rates: "Preise",
    },
    knowledgeSection: {
      preHeading: `Taxi Rates`,
      heading: `Was kostet eine Taxifahrt?`,
      paragraph: `In Deutschland werden Taxitarife behördlich von Städten oder Landkreisen festgelegt.
      Der Taxitarif Neumünster wurde zuletzt im Februar 2020 festgelegt und in der offiziellen
      Tarifverordnung veröffentlicht.
      Er ist für alle Taxis und Taxiunternehmen innerhalb des Pflichtfahrgebietes verbindlich und darf
      weder über- noch unterschritten werden. Dies wird mit in den Taxis installierten und behördlich
      geeichten Taxametern sichergestellt.`,
    },
    table: {
      basicCharges: "Grundgebühr",
      p1to2: "Kilometer 1 - 2",
      p3to6: "Kilometer 3 - 6",
      additional: "Jeder weitere Kilometer",
      waiting: "Stand-/Wartezeitro Stunde",
      secondHeading:
        "Montag bis Samstag von 23:00 Uhr abends bis 6:00 Uhr morgens. Sonntag ganztägig.",
      thirdHeading:
        "In der Rangliste der teuersten Taxitarife in Deutschland belegt der Taxitarif Neumünster",
      placesHeading: "Platz 150 von 217",
      calculate:
        "Grundlage der Berechnung der günstigsten und teuersten Tarife ist eine Taxifahrt von 8 Kilometern mit einer Fahrdauer von 20 Minuten im Tagtarif.",
    },
    description: {
      heading1: `Calculation example cost of a taxi ride`,
      para1: `Imagine you need  a Taxi from Kenedylaan, Eindhoven to Eindhoven Airport with two people.
            The ride is 6.2 kilometres  away and takes 15 minutes to reach the destination.
            Then the cost of a taxi ride should not exceed:`,
      firstPoint: `1.	Start rate = € 3.19`,
      secondPoint: `2.	Kilometres rate = (€ 2,35x10) € 23,5`,
      thirdPoint: `3.	Time rate = (€ 0.39x15) € 5,85`,
      fourthPoint: `4.	Total cost = (€3.19+€ 23,5+€ 5,85) € 32,54`,
      restOfPara: `So for a ride that is 10 km and takes 15 minutes you must not pay more than € 32.54.
            Of course, the same ride sometimes takes longer than 15 minutes because of a traffic jam.
            Then you pay more, and if ride goes faster than 15 minutes then you also have to pay less.
            At the end of the ride, you can ask the driver for a voucher.
            The voucher shows exactly how many minutes the ride took,
            and how many kilometres the distance was from your pickup location and end location.`,
      heading2: `Taxi waiting rate`,
      para2: `The driver can charge a waiting rate if you have agreed before the taxi ride has started.
            For example, the waiting rate applies if the taxi driver has to wait if his passenger's meeting expires.
            The maximum waiting rate is €43.94 per hour.`,
      heading3: `Compare taxi cost`,
      para3: `A taxi driver can offer you a fixed ride price. He may also charge a surcharge for additional services.
            For example, for carrying your suitcases.
            You must arrange the fixed price or surcharge before the taxi ride has started.`,
    },
  },
  footer: {
    contactHeading: "KONTAKTINFORMATIONEN",
    infoHeading: "Informationen",
    companyInfoHeading: "UNTERNEHMENSINFORMATIONEN",
    address: "Houckgeeststraat 13 Eindhoven",
    supportEmail: "service@schnelleintaxi.de",
    telefon: "+49 4321 7570460",
    ourMission: "OUR MISSION",
    infoLink1: "Startseite",
    infoLink2: "Allgemeine Geschäftsbedingungen",
    infoLink3: "Datenschutz",
    infoLink4: "kontaktieren Sie uns",
    infoLink5: "Blog",
    companyName: "Schnelleintaxi",
    kvk: "KVK 65752376",
    companyAddress: "Houckgeeststraat 13 Eindhoven",
    missionStatement: `We strive to provide our customers with professional, on-time,
    safe and comfortable taxi transportation and creating business opportunities for our drivers.`,
  },
  paymentScreen: {
    buttonText: "Pay",
  },
  airportPage: {
    taxiTo: "TAXI TO",
    airport: "AIRPORT",
    logoText: `Need a taxi to `,
    logoText2:
      ". At taxi spot you can book a taxi online, easily, safely and cheaply!",
    firstPara: `Are you planning to fly via `,
    firstPara1: `? And do you need transportation?
    There are several ways to travel to `,
    firstPara2: `. You can drive your car to the airport,
    use public transport, have someone take you away or opt for an airport taxi.
    Travelling by taxi is comfortable and fast, in addition you don't have to
    charge for parking at the airport and also not to walk far with bags and/or suitcases.
    Don't care if you're alone, with your family or other acquaintances.
    You don't have to worry, we've got you covered. Our drivers will provide
    you with a pleasant ride to `,
    firstPara3: `. This in combination with the
    skills of a professional taxi driver who ensures that you arrive at `,
    firstPara4: ` on time every time.`,
    firstQuestion: `Have a Questions?`,
    firstHeading: `How can I book a taxi to `,
    para2: `With us you can easily book a taxi to`,
    step1: `Step 1: Click Buchen Sie Ihr Taxi`,
    step2: `Step 2: Give the address from where you want to travel to `,
    step3: `Step 3: Choose the vehicle that suits you best?`,
    firstPoint: `Travel with max. 4 men to`,
    firstPoint1: `airport and little luggage then it is best
    to choose a standard car!`,
    secondPoint: `If you travel with 8 people to`,
    secondPoint1: `,  choose a minivan!`,
    step4: `Step 4: Enter your personal data and pay securely online.`,
    step5: `Step 5: Enjoy a pleasant and enjoyable ride.`,
    secondQuestion: `What is the price to `,
    secondPara: `When booking your taxi through the site, it will be automatically
    shown what the cost of the taxi ride will be.
    You enter your exact address with which our site calculates a precise price for you.
    You can pay online or with cash in the taxi. This price depends ofcourse on your exact location.
    If you are further away from Schiphol cost will be more than if you are closer of course. `,
    btnText: `Calculate your fare`,
    inGermany: `In Germany`,
    netherlandAirportCard1: `Our most popular airport in the Netherlands is of course`,
    netherlandAirportCardRest: `but apart from that we also transport passengers almost daily to:`,
    netherlandAirportCard2: `In addition to in the Netherlands, we also transport passengers
    to Belgium airport:`,
    fixedPriceQuestion: "Whats the process?",
    fixedpriceHeading: "FIXED PRICES FOR MAX 4,",
    fixedpriceHeading2: "PEOPLE TO ",
    fixedpriceHeading3: " AIRPORT",
    fixedpricePara: "Fixed prices for max 4 people to ",
    fixedpricePara2: " airpot",
    fromText: "Of",
    priceText: "Price",
  },
  twoCities: {
    taxiTo: "TO",
    taxiFrom: "TAXI FROM",
    preHeading: "Have a Question?",
    heading1: "Why book at Schnelleintaxi??",
    firstPara:
      "Ordering a taxi via Schnelleintaxi  guarantees the following things:",
    point1: "A professional driver who is always there for you",
    point2: "An excellent 24/7 service, to reach any time of the day",
    point3: "An incredibly low rate, sometimes with discounts of up to 50%.",
    paraRest: `It is possible to engage us for regular transport,
    however it is also possible to order a taxi for all your trips to and from the airport.
    Schnelleintaxi's taxis will always get you to the gate in time.
    In addition, our experienced and specialized drivers always help you load the luggage.`,
    para2: `Quality, convenience, excellent service for a low price.
    That's exactly what we at Schnelleintaxi  stand for.
    Make an appointment today or book a taxi,
    before you know it you are already at the destination!`,
    btnTxt: "Calculate your Fare!",
    heading2: "How much does a taxi from ",
    headingPart2: " to ",
    headingPart3: " cost?",
    para3: `The cost of a taxi ride depends on: the starting rate, the distance and the duration of
    the ride. This rate prevents you as a customer from having to pay too much.
    This maximum rate is determined by the government. A taxi company may not
    drive higher than the maximum fare. When booking your taxi through the site,
    it will be automatically announced what the cost of the taxi ride will be.
    You enter your exact address with which our site calculates a precise price for you.
    This price depends of course on your exact location. The longer the distance
    the more discount you get, discounts can be up to 50%. If it is a short
    distance you never pay more than the maximum rate. Would you like to know more
    about taxi fares? Take a look at
    our this page `,
    paymentsubheading: "Something to Share!",
    paymentheading: "Payment methods",
    paymentpara: `At Schnelleintaxi  you pay how you like best.
    You can easily and securely pay online in advance with an additional 5% discount.
    Online you can pay by credit card, iDeal,PayPal and Apple Pay.
    If you choose to pay online during the order process
    you will receive an additional 5% discount on your ride.`,
    processHeading: "Whats the process?",
    fixedPriceHeading: "OUR FIXED PRICES TO ",
    fixedPriceFrom: "From ",
    fixedPricePara: ` we have fixed prices for popular destinations that are maintained.
    In the table below you will find prices to the popular destinations.`,
    fromText: "From",
    priceText: "Price",
    costEstimate: {
      headingFirstPart: `How do I book a taxi to `,
      paraText: `With us you can easily book a taxi to `,
      paraText2: ` .`,
      firstPoint: `Step 1: Click Buchen Sie Ihr Taxi`,
      secondPoint: `Step 2: Give the address from where you want to travel to `,
      secondPoint2: `.`,
      thirdPoint: `Step 3: Choose the vehicle that suits you best?`,
      secondPara: `Are you travel with max. 4 persons to `,
      secondPara2: ` and little luggage
            then it is best to choose a standard car!
      If you travel to `,
      secondPara3: ` with 8 persons, choose a minivan!`,
      fourthPoint: `Step 4: Enter your personal data and pay securely online.`,
      fifthPoint: `Step 5: Enjoy a pleasant and enjoyable ride.`,
      buttonText: `Buchen Sie Ihr Taxi`,
    },
    moreDiscussion: "More to discuss.",
    regularCust: "Regular customers",
    regularCustPara: `We appreciate that you choose us, which is why we give our regular customers
    10% discount on already low prices.
    Do you travel more than 4times a week?
    Please contact us to be eligible for our regular customers program.`,
    businessTpt: "Business transport from ",
    businessTpt2: " to ",
    regularCustPara2: `For all your business transport you can of course also go to Schnelleintaxi.
    With a private driver you can focus in the car on things other than the road.
    Do you need relaxation during your trip?
    Schnelleintaxi is happy to take away traffic responsibility.
    Thanks to our private drivers, you can focus on business tasks or relaxation during your trip.`,
    bullet1: "Always a certified private driver",
    bullet2: "Representative private drivers",
    bullet3: "Attractive rates",
    bullet4: "Lots of comfort",
    driverHeading: "Our Drivers",
    driverPara: `We have well-trained and qualified drivers for companies and individuals.
    All our drivers are certified and trained in dealing with unexpected situations.
    All our drivers have a basic first aid certificate.`,
    popularCities: "Our Popular Cities",
    popularCitiesPara: `We transport you everywhere from Eindhoven to the Netherlands, Belgium and Germany.
    And from every city to Eindhoven.`,
    popluarinNl: `Our popular cities in the Netherlands`,
    popularInBelgium: "Popular cities in Belgium",
    popularInDe: "Popular cities in Germany",
    socialMedia: "Join us on social media",
    socialMediaPara: "FOLLOW US ON SOCIAL MEDIA",
  },
  bookingSteps: {
    back1: "zurück zum Ort",
    next1: "wählen",
    back2: "Rückkehr zum ausgewählten Taxi",
  },
  bookingBenefits: {
    benefit1: "Corona Beweis",
    benefit2: "Track-Fahrer",
    benefit3: "Kostenlose Stornierungen und Änderungen",
    benefit4: "Chauffeur",
  },
  tracking: {
    errorText: "Something went wrong",
    driverArrived: "Fahrer ist angekommen",
    rideCancelled: "Reise wurde abgesagt",
    rideNotStarted: "Fahrt noch nicht begonnen",
    rideCompleted: "Fahrt ist beendet",
    pickUp: "Von",
    drop: "Zu",
    dateTimeChange: "Datum und Uhrzeit ändern",
    payment: "Zahlung",
    proceed: "Zusteigen",
    cancelAlert: "Sind Sie sicher, dass Sie diese Fahrt absagen möchten?",
    cancelRide: "Reise stornieren",
    pickUpDate: "Abholdatum / -zeit ",
    shareThisLink: "Fahrverbindung teilen",
  },
  customerDetail: {
    customer_info: "Kundendetails",
    name: "Name",
    email: "Email",
    phone: "Telefonnummer",
    ride_details: "Fahrdetails",
    pick_loc: "Treffpunkt",
    drop_loc: "Ort ablegen",
    ttl_fare: "Gesamtfahrpreis",
    pay_mode: "Zahlungsart",
    ride_date: "Datum",
    driver_earn: "Du verdienst",
  },
};

export default DE;
