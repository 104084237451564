import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import client from "./gatsby-theme-apollo/client";
import LanguageProvider from "./lang";
import "react-flags-select/css/react-flags-select.css";
import getTitle from "./utils/getTitle";
import { isBrowser } from "./utils/general";

// console.log = () => null;

const WrapRootElement = ({ element }) => {
	if (isBrowser()) document.title = getTitle(window.location.pathname);
	return (
		<LanguageProvider>
			<ApolloProvider client={client}>{element}</ApolloProvider>
		</LanguageProvider>
	);
};

WrapRootElement.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	element: PropTypes.any.isRequired,
};

export default WrapRootElement;
