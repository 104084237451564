export default (path) => {
	switch (path) {
		case "/en/airport-transportation/":
			return "Taxi service, Airport taxi - Neumünster Airport Taxi";
		case "/airport-transportation/":
			return "Taxi service, Airport taxi - Neumünster Airport Taxi";
		case "/en/taxi-to-neumunster/":
			return "Online Taxi Booking Services - Uber Taxi Booking Services";
		case "/taxi-to-neumunster/":
			return "Online Taxi Booking Services - Uber Taxi Booking Services";
		case "/en/taxi-rates/":
			return "Taxi Airport Neumünster - Taxi Neumünster Airport";
		case "/taxi-rates/":
			return "Taxi Airport Neumünster - Taxi Neumünster Airport";
		case "/en/about/":
			return "Taxi Near Neumünster Airport - Schnelleintaxi.DE";
		case "/about/":
			return "Taxi Near Neumünster Airport - Schnelleintaxi.DE";
		case "/en/":
			return "Taxi Kiel | Taxi Hamburg | Taxi Services near Me | VIP Taxi Kiel";
		case "/":
			return "Taxi Kiel, Hamburg | Taxiservice in meiner Nähe | VIP Taxi Kiel";
		default:
			return "Taxi Centrale Neumünster - Taxi Near Me Services";
	}
};
