import fetch from "isomorphic-fetch";
import { HttpLink, InMemoryCache, Subs } from "@apollo/client";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { SubscriptionClient } from "subscriptions-transport-ws";
import * as ws from "ws";
import { isBrowser } from "../utils/general";
//websocket link config
const wsForNode = isBrowser() ? ws : null;

const wsLink = isBrowser()
  ? new WebSocketLink({
      uri: process.env.GATSBY_WSS_URL,
      options: {
        reconnect: true,
      },
      wsForNode,
    })
  : null;

// http link config
const httpLink = new HttpLink({
  uri: process.env.GATSBY_API_URL,
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : null;
  let sessionToken = isBrowser()
    ? JSON.parse(sessionStorage.getItem("token"))
    : null;

  // return the headers to the context so httpLink can read them
  console.log("localstroage token", token);
  console.log("sessionToken token", sessionToken);

  return {
    headers: {
      ...headers,
      authorization:
        token || sessionToken ? `Bearer ${token ? token : sessionToken}` : "",
    },
  };
});
const link = isBrowser()
  ? split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    )
  : httpLink;
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

export default client;
