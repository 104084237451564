export const isBrowser = () => typeof window !== "undefined";

export const getPrefix = () => {
  if (isBrowser()) {
    let p = window.location.pathname.split("/");
    return p[1] && p[1] === "en" ? "en" : "";
    return "";
  }
  return "";
};

export const langLink = (link) => {
  let prefix = getPrefix();
  return `/${prefix}${prefix ? "/" : ""}${link || ""}`;
};

export default {
  isBrowser,
};
