export const EN = {
  // common: {
  //     phonePlaceholder: "Enter Phone Number"
  // },

  navbar: {
    airportText: "Airport Transportation",
    aboutUsText: "About us",
    myAccountText: "My Account",
    contact: "Contact",
    taxiEindhoven: "Taxi Neumünster",
    taxiRate: "Taxi Rates",
    airportTransportation: "Airport Transportation",
    signoutText: "Sign Out",
    loginText: "Login",
  },
  hotelDashboard: {
		createRide: {
			getTaxiText: "Create New Ride",
			selectTaxi: "Select Taxi",
			customerInfo: "Customer Information",
			para: "If you cannot book rides via the dashboard",
			option1:"Book a ride by phone on 0402405999",
			option2: "Or call +31624509009 for technical questions",
      pickUp:"From",
      drop:"To",
      notesText:"Notes",
      dateTimeText:"Date/Time",
      bookText:"Book",
      placeSelect:"Select Place",
      typeText:"Type Here"
		},
    subHeader:{
      currentRides:"Current Rides",
      pastRides:"Past Rides",
      excelExport:"Export to Excel",
      sortBy:"Sort by Date",
      ascText:"Ascending Order",
      desText:"Descending Order"
    },
    tableHeader:{
      passanger:"Passanger",
      ride:"Ride",
      driver:"Driver",
      name:"Name",
      phone:"Phone",
      room:"Room",
      status:"Status",
      arrivingIn:"Arriving In",
      price:"Price",
      commision:"Commision",
      track:"Track",
      changeDateTime:"Change Date/Time",
      cancel:"Cancel",
      car:"Car"
    },
    tabelRow:{
      noRows:"No Rides Found"
    }
	},
  hotelLoginText:{
    emailError:"Kindly fill the email",
    passwordError:"Kindly fill the password",
    validEmail:"Kindly fill valid email",
    passwordText:"Password",
    passwordPlaceholder:"password",
    rememberText:"Remember Me",
    headerText:"Schnelleintaxi Business",
    loginText:"Login with the login details provided by Schnelleintaxi. Have you forgotten or lost your login details? Then contact us by telephone on +31402405999"
  },
  privacyPolicy: {
    culture: "en",
  },
  covid: {
    para1:
      "At Schnelleintaxi we want to give our customers a safe an cheap ride in these difficult times. To insure your safety and the safety of our drivers we have made measures.",
    listItem1:
      "All our taxis have a safety screen between the driver and passengers to ensure the safety between them.",
    listItem2:
      "All our drivers will have a non-surgical mask please don’t feel shy to ask the driver to put on the mask, after all it is your safety (if driver does not wear one).",
    listItem3:
      "At the back seat of our taxi’s you will find a free hand sanitiser, feel free to use it as many times as you like for free.",
    listItem4:
      "Our drivers are equipped with disinfectants and wipes to clean hands and contact points",
    listItem5:
      "During each ride, and between journeys, the vehicle is ventilated as much as possible.",
    listItem6:
      "The contact points in the vehicle (think door handle, armrest, seat belt, pin equipment and handle) are extra cleaned.",
    para2:
      "We ask our passengers in these difficult time to observe the following:",
    listItem7: "Get in and out of the taxi yourself.",
    listItem8: "If you're alone, sit in the back right.",
    listItem9: "Put the seat belt on yourself.",
    listItem10:
      "If possible, place luggage in the boot or hold it on your lap.",
    listItem11: "Pay digitally or with pin/credit card. Avoid cash payments.",
    listItem12:
      "There is no limit on the number of passengers per vehicle. All seats may be used.",
    listItem13:
      "Where several passengers are transported, where 1.5 meters is not observable between these passengers, we ask them to use a mask",
    para3:
      "To ensure everyone safety we ask our customers who have symptoms of COVID-19 like",
    listItem14: "Colds such as nose cold, runny nose, sneezing, sore throat",
    listItem15: "Coughing",
    listItem16: "Trouble",
    listItem17: "Increase or fever",
    listItem18: "Sudden loss of smell and/or taste (without nasal congestion)",
    para: "To stay at home!",
    para4:
      "If you have one or more symptoms that match an infection with COVID-19, you can be tested.",
    heading1: "Mild complaints",
    headingPara1:
      "Cold symptoms, nose cold, runny nose, sneezing, sore throat, cough, increase or if you suddenly no longer smell or taste properly. Get tested and stay home until the results are known.  Check  out Rijksoverheid.nl how to make an appointment for a test.",
    heading2: "Serious symptoms",
    headingPara2:
      " Tightness and/or fever (above 38 degrees) or if you feel sicker and more ill. Call the doctor or the gp post immediately.",
    para5: "Measures to prevent spread",
    listItem19:
      "Often wash your hands with soap and water, then dry your hands well.",
    listItem20: "Coughs and sneezes in the inside of your elbow.",
    listItem21:
      "Use paper handkerchiefs to blow your nose and then discard them.",
    listItem22: "Don't shake hands.",
    listItem23: "Keep 1.5 meters away (2 arm lengths) from others.",
  },
  home: {
    routeSelection: {
      pickUpPlaceHolder: "From where",
      noPlaceHolder: "No.",
      noOfPassengersText: "Number of passengers",
      destinationPlaceHolder: "Where to",
      onewayText: "One Way",
      returnDateTimeText: "Select Return Date and Time",
      returnText: "Return",
      returnPlaceholder: "Return Date",
      nextButtonText: "NEXT",
      nextOneText: "Next one",
    },
    ourPartners: {
      mainHeading: "Our partners",
    },
    benefits: {
      benefit1: "Track driver",
      benefit2: "Share tracking link",
      benefit3: "Free cancelation and changes",
      benefit4: "Corona proof",
      mainHeading1: "Reliable",
      mainHeading2: "Safe",
      mainHeading3: "Control",
      mainHeading4: "Corona proof",
      para1:
        "With our booking system you can track you driver with a tracking link provided in mail, you will always know the location of the driver and contact them if necessary.",
      para2:
        "See you drivers information in the tracking link and email. Like name, phone number and car details. Share your tracking link with your friends.",
      para3:
        "You can cancel or change you ride free till 1 hour before pickup time.",
      para4:
        "All our taxi’s have a safety screen between the passenger  and the driver. Our drivers have a mask and it is recommended for passengers as well",
    },
    dateTimePicker: {
      chooseDateTimeHeading: "Choose Date and Time",
      nowTwentyMinText: "Now in 20 minutes",
      todayText: "Today",
      tommorowText: "Tommorow",
      customText: "Custom",
      nowText: "Now",
      selectText: "Select",
      culture: "en",
    },
    heroSection: {
      getTaxiText: "Book your Taxi",
      whereToGoText: "Where do you want to go?",
      welcomeText: "Welcome To",
      taxiText: "SCHNELLEINTAXI",
      selectTaxi: "Select Taxi",
      about: {
        ourCompanyText: `ABOUT`,
        aboutText: `US`,
      },
    },
    covidBanner: {
      para:
        "Your safety is of paramount importance to us! Read more about our precautionary measures regarding taxi use during COVID-19.",
      linkText: "Read more",
      culture: "en",
    },
    howItWorks: {
      processText: "What's the process?",
      mainHeading: "How Does It Work",
      mainPara:
        "Our goal is to take passengers as easy, fast and cheap as possible from A to B. We can achieve this by using professional drivers. You can compare us to Uber, but with the certainty of a skilled driver with a lot of driving experience and a well-regulated system. If you order a taxi at Schnelleintaxi, the information goes to one of our affiliated drivers closest to the pickup location. If our driver accepts the ride, you will immediately see who accepted it on the screen, what kind of car the driver has and what his phone number is. In this way we try to let you experience an optimal journey.",
      subSections: {
        firstSection: {
          heading: "Enter Your Travel Information",
          para:
            "First of all, enter your pick-up and final address with home number. Then choose which car you want to be transported with. Then choose at what time you would like to have the taxi (within now and 20 minutes or later). And finally, you fill in your personal data.",
        },
        secondSection: {
          heading: "See Who Accepted Your Ride",
          para:
            "When you enter your personal data and click ''find a driver'' and our system searches for the nearest driver. If then one of our drivers accepts the ride, you can see which driver accepted the ride, with which car you are transporting, what color the car has and what the license plate of the car is.  This way you always know with certainty that you get in the right car!",
        },
        thirdSection: {
          heading: "Enjoy Your Ride",
          para:
            "When the driver accepts a ride, you can move on to the payment. If the payment has been successful, you and the driver will receive a notification stating that the payment has been successful. All you're waiting for is to enjoy your ride!",
        },
      },
    },

    ourCars: {
      mostPopular: "Most Popular",
      subHeading: "Choose your Car",
      mainHeading: "Our Prices",
      paymentHeading: "Payment methods",
      cars: {
        firstCar: {
          name: "During the day",
          startPrice: "Basic fee : ",
          farePerKm1: "Kilometers 1 - 2 : ",
          farePerKm2: "Kilometers 3 - 6 : ",
          farePerKm3: "Each additional kilometer : ",
          farePerKm4: "Standing / waiting time per hour : ",
        },
        secondCar: {
          name: "At night",
          dsc: "Monday to Saturday from 11:00 p.m. to 6:00 a.m. Sunday all day",
          startPrice: "Basic fee : ",
          farePerKm1: "Kilometers 1 - 2 : ",
          farePerKm2: "Kilometers 3 - 5 : ",
          farePerKm3: "Each additional kilometer : ",
          farePerKm4: "Standing / waiting time per hour : ",
        },
      },
    },

    paymentMethods: {
      mainHeading: "Payment methods",
      para:
        "Schnelleintaxi makes it easy to pay online, pay how you like it best. Pay with GiroPay, Apple pay, PayPal, credit card or cash in a taxi. You can also pay with your Card in the taxi. You will always receive an invoice / confirmation of your payment in your post, you can always request a receipt yourself after the ride in the taxi. You can also pay later by invoice. Please note that payment after payment is only possible for business customers or customers who have an account with Schnelleintaxi.",
    },

    fixedPrices: {
      mainHeading: "Our Fixed Prices",
      plans: {
        firstPlan: {
          name: "Neumunster-Hamburg",
          price: "Approx. 90 €",
          noOfPassengers: "3 passengers",
        },
        secondPlan: {
          name: "Neumünster Airport Hamburg",
          price: "Approx. € 85",
          noOfPassengers: "2 passengers",
        },
        thirdPlan: {
          name: "Neumünster-Kiel",
          price: "Approx. 50 €",
          noOfPassengers: "5 passengers",
        },
        fourthPlan: {
          name: "Kiel-Hamburg",
          price: "Approx. 135 €",
        },
        fifthPlan: {
          name: "Kiel Airport Hamburg",
          price: "Approx. € 125",
        },
      },
    },

    taxionspot: {
      mainHeading: "SCHNELLEINTAXI",
      // subHeading:
      //   "Ordering A Taxi via Schnelleintaxi Guarantees The Following: ",
      subHeading: "Always on the go for you!",
      subHeading2: "Your safety counts!",
      para1:
        "Schnellintaxi is flexible and fast, we are ready for action in Kiel, Neumünster and Hamburg. With our 80 driver and his luxury cars, we will take you from A to B easily, safely and comfortably.",
      para2:
        "At Schnellintaxi we don't see our customers as customers, but as our guests, once you get into our taxi, you will be treated as our guest. If you choose Schnellintaxi, we guarantee you.",
      listItem1: "Excellent service",
      listItem2: "24/7 availability",
      listItem3: "English speaking drivers must",
      listItem4: "Decent clean cars",
      listItem5: "Neat and neat drivers",
      listItem6: "Protection against COVID-19",
      safetyPara:
        "Whoever orders a taxi can express various wishes. In order to contain the spread of the coronavirus, a taxi with a protective wall can now also be ordered. Day and night. Our dispatcher will be happy to receive your requests.",
      btnText: "Read More",
    },
    wantToKnowMore: {
      subHeading: "Do you have a questions?",
      mainHeading: "Want To Know More?",
      para:
        "Schnellintaxi is a transparent and service-oriented company, we are always ready for all your questions. Therefore you can reach us in different ways!",
      contacts: {
        office: {
          name: "Our Office",
          para: "Houckgeeststraat 13 Eindhoven",
        },
        phone: {
          name: "Our Phone",
          para: "+49 4321 7570460",
        },
        email: {
          name: "Our Email",
          para: "service@schnelleintaxi.de",
        },
      },
    },

    testimonials: {
      mainHeading: "Testimonials",
      subHeading: "What our customers think of us",
    },
  },
  personalInfo: {
    formTitle: "Personal Information",
    firstNameRequired: "First Name Required",
    lastNameRequired: "Last Name Required",
    emailRequired: "Email Required",
    phoneRequired: "Phone Required",
    journeyTypeRequired: "Journey type required",
    firstNamePlaceHolder: "First Name",
    lastNamePlaceHolder: "Last Name",
    emailPlaceHolder: "Enter Your Email",
    phonePlaceHolder: "Enter Your Phone",
    houseNoRequired: "House No Required",
    houseNoPlaceHolder: "House No",
    journeyTypePlaceHolder: "Type of Journey",
    returnPlaceholder: "Return",
    oneWayPlaceholder: "One Way",
    buttonText: "Next",
    paymentTypeText: `Payment Type`,
    cashText: `Cash`,
    onlineText: `Online Payment`,
    notes: "Notes",
		textareaPlaceholder: "Flight number, comment etc...",	
  },
  about: {
    ourCompany: {
      headingText: "Our Company",
      paraText:
        "Our dream is to get passengers from A to B as easily, quickly and cheaply as possible. We can achieve this by employing professional drivers. Well you can compare us with Uber, but then with the confidence and professionalism of classic taxis. If you order a taxi at Schnelleintaxi, the information goes to one of our connected drivers who is close to the pick-up location. If our driver accepts the ride, you will immediately see on the screen who has accepted it, what kind of car the person has and what his telephone number is. You will also receive an SMS confirmation, in the SMS you will also receive a link with which you can follow the driver, call and cancel your journey. In this way we try to ensure that our passengers continue to have an optimal journey.",
    },
    whyTaxiOnSpot: {
      headingText: "Why choose Schnelleintaxi?",
      paraText1: `Ordering a taxi via Schnelleintaxi guarantees the following:`,
      bullet1: `A professional driver who is always ready for you`,
      bullet2: `An excellent 24/7 service, accessible every moment of the day`,
      bullet3: `A very low rate, sometimes with discounts of up to 60%`,
      paraText2: `It is possible to hire us for regular transport, but it is also
	  possible to order a taxi for all your trips to and from the airport. We
	  will ensure that you are well in time for your flight. In addition, our
	  helpful drivers always assist you in loading the luggage.`,
      paraText3: `Quality, convenience and excellent service for a low price. That is exactly where we are
	  from Schnelleintaxi. Make an appointment today or book a taxi,
	  before you know it you are already at your destination!`,
    },
  },
  confirmBooking: {
    reviewBookingText: "Review The Booking",
    outwardText: "Outward ride",
    numOfTravelersText: "Number of Travellers",
    contactDetailsText: "Your Contact Details",
    totalText: "Total",
    btnText: "Order Now",
    paymentTypeText: `Payment Type`,
  },
  rideAccepted: {
    reviewBookingText: "Review The Booking",
    outwardText: "Outward ride",
    numOfTravelersText: "Number of Travellers",
    contactDetailsText: "Your Contact Details",
    totalText: "Total",
    btnText: "Pay Now",
    taxiDetailText: "Taxi Details",
    driverDetailsText: "Driver Details",
    modelText: "Model",
    licenceText: "Licence",
    colorText: "Color",
    nameText: "Name",
    phoneText: "Phone",
    taxi: "Taxi Details",
  },
  payment: {
    outwardText: "Outward ride",
    mollieText: "Mollie Payment",
    molliePara: `Safe money transfer using your bank account. Visa, Maestro,
    Discover, American Express.`,
    btnText: "Pay",
    payCash: "Pay cash in the taxi or with card in the taxi",
    discountText: "Pay online and recieve  * % extra discount",
    paymentMethodText: "Select Payment Method",
    termNote: "By Compeleting payment you agree to our  ",
    termsAndConditions: "Terms and Conditions",
    cashPay: "Cash Payment",
    creditCard: "Credit Card",
    paySecure: "Pay Secure",
    paymentHeading: "Payment",
    verify: "Verify",
    phone: "Phone Number",
    goback: "Go Back",
    resend: "Resend Code",
    verifyPhone: "Verify Phone Number",
  },
  resetPassword: {
    heading: "Enter New Password",
    password: "Enter Password",
    repeatPassword: "Repeat Password",
    btn: "Next",
  },
  signup: {
    heading: "Create an account",
    name: "Name",
    email: "Email Address",
    phone: "Phone Number",
    password: "Password",
    repeatPassword: "Repeat Password",
    agree: "I agree to the Google Terms of Service and Privacy Policy",
    btn: "Register",
    alreadyMember: "Are you already a member?",
  },
  login: {
    rememberText: "Remember Me",
    forgotText: "Forgot your Password?",
    dontHaveText: `Don't Have an account yet?`,
    btnText: "Login",
    emailVerifyHeading: "Email Verification",
    emailVerifyMsg: "Enter A Valid Email To Reset Password",
    emailVerifyPlaceholder: "Enter Email",
    emailVerifyBtn: "Next",
  },
  selectCar: {
    mostPopular: "Most Popular",
    numOfPassengersText: "Number of passengers",
    nowText: "Now",
    btnText: "Select",
    totalText: "Total",
    bigVanText: "Big Van",
    forText: "For",
    personText: "Person",
    notFound: "No Taxi Found",
  },
  bookingTime: {
    rightNowText: "I need a taxi right now",
    ridelateText: "I need a taxi later",
    rightNowSubText: "Book a Taxi within 20 Minutes",
    rideLaterSubText: "Plan your Taxi Ride",
    btnText: "Next",
  },
  myAccount: {
    myAccountText: "My Account",
    accDetailsText: "Account Details",
    accDetailsSubText: "Change the details of your account below",
    contactDetails: "Contact Details",
    nameText: "Name",
    emailtext: "Email Address",
    phoneText: "Phone Number",
    passwordText: "Password",
    deleteText: "Delete Account",
    deleteSubText: "Delete Your Account From Our Servers",
    deleteBtnText: "Remove Account",
    changeBtnText: "Change",
    myBookingText: "My Bookings",
  },
  myBooking: {
    myBookingText: "My Bookings",
    myAccountText: "My Account",
    bookingNumberText: "Booking Number",
    outwardText: "Outward Ride",
    returnRideText: "Return Ride",
    passengersText: "Passengers",
    statusText: "Status",
    actionText: "Action",
    detailBtnText: "Details",
    missedBtnText: "missed",
    scheduledBtnText: "scheduled",
    completedBtnText: "completed",
  },
  rideDetails: {
    bookingNumberText: "Booking Number ",
    outwardText: "Outward ride",
    driverDetailsText: "Driver Details",
    taxiDetailText: "Taxi Details",
    numOfPassengersText: "Number of Passengers",
    cancelBtnText: "Cancel Ride",
    totalText: "Total",
    tripDateText: "Trip Date",
    tripTimeText: "Trip Time",
    modelText: "Model",
    licenceText: "Licence",
    colorText: "Color",
    nameText: "Name",
    phoneText: "Phone",
    passengerText: "Passengers",
  },
  thankyou: {
    thankYouText:
      "Thank you for booking at Schnelleintaxi, login/register to see you rides and make changes. Or check you E-mail for your current ride details.",
    buttonText: "Return To Site",
  },
  thank_you: {
    thank_YouText:
      "Thank you for contacting Schnelleintaxi, we will get in touch with you as soon as possible.",
    buttonText: "Return To Site",
    trackText: "Track your driver on this link!",
  },
  bookingMenu: {
    locationText: "Location",
    bookingDetail: "Booking Details",
    waitingText: " Waiting",
    doneText: "Done",
  },
  rideWaiting: {
    text: `our system is looking for a driver which can take up to 60 sec`,
  },
  notFound: {
    text:
      "Unfortunately There Is No Driver Available At The Moment. Try Again In A Few Minutes.Or Call Our Central Office Directly",
    tryAgainButton: "Try Again",
    callButton: "Call",
  },
  blog: {
    culture: "en",
  },
  contactUs: {
    contactUsText: {
      contact: "Contact",
      us: "Us",
    },
    heading: "Or send us a Message",
    name: "Name",
    email: "Email",
    subject: "Subject",
    message: "Message",
    send: "Send",
    nameRequired: "Name Required",
    emailRequired: "Email Required",
    subjectRequired: "Subject Required",
    messageRequired: "Message Required",
  },
  taxiToEindhoven: {
    ourCompany: {
      headingText: "Our Company",
      paraText:
        "Our dream is to get passengers from A to B as easily, quickly and cheaply as possible. We can achieve this by employing professional drivers. Well you can compare us with Uber, but then with the confidence and professionalism of classic taxis. If you order a taxi at Schnelleintaxi, the information goes to one of our connected drivers who is close to the pick-up location. If our driver accepts the ride, you will immediately see on the screen who has accepted it, what kind of car the person has and what his telephone number is. You will also receive an SMS confirmation, in the SMS you will also receive a link with which you can follow the driver, call and cancel your journey. In this way we try to ensure that our passengers continue to have an optimal journey.",
    },
    hero: {
      taxi: "Taxi to",
      city: "Neumünster",
    },
    whyTaxiOnSpot: {
      preHeadingText: "Have a Question?",
      headingText: "Why choose Schnelleintaxi?",
      paraText1: `Ordering a taxi via Schnelleintaxi guarantees the following:`,
      bullet1: `A professional driver who is always ready for you`,
      bullet2: `An excellent 24/7 service, accessible every moment of the day`,
      bullet3: `A very low rate, sometimes with discounts of up to 60%`,
      paraText2: `It is possible to hire us for regular transport, but it is also
	  possible to order a taxi for all your trips to and from the airport. We
	  will ensure that you are well in time for your flight. In addition, our
	  helpful drivers always assist you in loading the luggage.`,
      paraText3: `Quality, convenience and excellent service for a low price. That is exactly where we are
	  from Schnelleintaxi. Make an appointment today or book a taxi,
	  before you know it you are already at your destination!`,
      secondHeading: `How much does a taxi to
			Neumünster cost?`,
      secondPara: `The cost of a taxi ride depends on: the starting rate, the distance and the duration of the ride. This  rate prevents you from having to pay too much as a customer. This  maximum rate is determined by the government. A taxi company must not exceed the maximum rate. While booking your taxi trough the site, it will automatically show what the cost of the taxi ride will be. You enter your exact pickup and drop-off location with that information that our site calculates a precise price for you. This price depends of course on your exact location. The longer the distance the more discount you get, discounts can reach up to 50%. If it is a short distance you will never pay more than the maximum rate. `,
      buttonText: `Book your taxi`,
    },
    costEstimate: {
      headingFirstPart: `How do I book a taxi `,
      headingSecondPart: `to Neumünster?`,
      paraText: `With us you can easily book a taxi to Neumünster.`,
      firstPoint: `Step 1: Click Book your taxi`,
      secondPoint: `Step 2: Give the address from where you want to travel to Neumünster`,
      thirdPoint: `Step 3: Choose the vehicle that suits you best?`,
      secondPara: `Are you travel with max. 4 persons to Neumünster and little luggage
			then it is best to choose a standard car!
			If you travel to Neumünster with 8 persons, choose a minivan!`,
      fourthPoint: `Step 4: Enter your personal data and pay securely online.`,
      fifthPoint: `Step 5: Enjoy a pleasant and enjoyable ride.`,
      buttonText: `Book your taxi`,
    },
  },
  airportTransport: {
    hero: {
      airport: `Airport`,
      transport: "Transportation",
    },
    easyBooking: {
      heading: `Book easily and online with Schnelleintaxi`,
      paragraph: ` At Schnelleintaxi you can easily and cheaply book a taxi to all airports in the Netherlands, Belgium and Germany.
			It doesn't matter where you order a taxi.
			Our automatic dispatcher system ensures that one of our unloaded driver who is close to
			the address is assigned the ride. This ensures that you can order a taxi
			for 3 hours in advance but can still enjoy stress free, safe and of course a cheap ride.
			An ride to an airport is often cheaper than ride elsewhere with the same distance.
			If you want to know what your ride is going to cost click on the
			"Book your taxi" enter your pickup and dropoff location and see what the price is.`,
      buttonText: `Book your taxi`,
    },
    germanAirports: {
      heading: `In Germany`,
    },
    netherlandAirports: {
      cardtitle1: `Our most popular airport in the Netherlands is of course Schiphol,
			but apart from that we also transport passengers almost daily to:`,
      cardTitle2: `In addition to in the Netherlands, we also transport passengers to belgium airport:`,
    },
  },
  driverDetail: {
    culture: "en",
  },
  taxiRates: {
    hero: {
      taxi: `Taxi`,
      rates: "Rates",
    },
    knowledgeSection: {
      preHeading: `Taxi Rates`,
      heading: `How much does a taxi ride cost?`,
      paragraph: `The cost of a taxi ride depends on: the starting rate,
            the distance and the duration of the ride.
            This is a maximum rate which prevents you from having to pay too much as a customer.
            This maximum rate is determined by the government. A taxi company must not ask more than the maximum rate.
            In the table below you can see the maximum rate of a taxi.`,
    },
    table: {
      basicCharges: "Basic charge",
      p1to2: "Kilometer 1 - 2",
      p3to6: "Kilometer 3 - 6",
      additional: "Every additional kilometer",
      waiting: "Standing / waiting time per hour",
      secondHeading:
        "Monday to Saturday from 11:00 p.m. to 6:00 a.m. Sunday all day.",
      thirdHeading:
        "In the ranking of the most expensive taxi tariffs in Germany, the Neumünster taxi tariff occupies",
      placesHeading: "150th place out of 217",
      calculate:
        "The basis for calculating the cheapest and most expensive tariffs is a taxi ride of 8 kilometers with a travel time of 20 minutes at the daily rate.",
    },
    description: {
      heading1: `Calculation example cost of a taxi ride`,
      para1: `Imagine you need  a Taxi from Kenedylaan, Neumünster to Neumünster Airport with two people.
			The ride is 6.2 kilometres  away and takes 15 minutes to reach the destination.
			Then the cost of a taxi ride should not exceed:`,
      firstPoint: `1.	Start rate = € 3.19`,
      secondPoint: `2.	Kilometres rate = (€ 2,35x10) € 23,5`,
      thirdPoint: `3.	Time rate = (€ 0.39x15) € 5,85`,
      fourthPoint: `4.	Total cost = (€3.19+€ 23,5+€ 5,85) € 32,54`,
      restOfPara: `So for a ride that is 10 km and takes 15 minutes you must not pay more than € 32.54.
			Of course, the same ride sometimes takes longer than 15 minutes because of a traffic jam.
			Then you pay more, and if ride goes faster than 15 minutes then you also have to pay less.
			At the end of the ride, you can ask the driver for a voucher.
			The voucher shows exactly how many minutes the ride took,
			and how many kilometres the distance was from your pickup location and end location.`,
      heading2: `Taxi waiting rate`,
      para2: `The driver can charge a waiting rate if you have agreed before the taxi ride has started.
			For example, the waiting rate applies if the taxi driver has to wait if his passenger's meeting expires.
			The maximum waiting rate is €43.94 per hour.`,
      heading3: `Compare taxi cost`,
      para3: `A taxi driver can offer you a fixed ride price. He may also charge a surcharge for additional services.
			For example, for carrying your suitcases.
			You must arrange the fixed price or surcharge before the taxi ride has started.`,
    },
  },
  footer: {
    contactHeading: "CONTACT INFORMATION",
    infoHeading: "INFORMATION",
    companyInfoHeading: "COMPANY INFORMATION",
    address: "Houckgeeststraat 13 Eindhoven",
    supportEmail: "service@schnelleintaxi.de",
    telefon: "017630135988",
    infoLink1: "Home",
    infoLink2: "Terms & Conditions",
    infoLink3: "Privacy Policy",
    infoLink4: "Contact Us",
    infoLink5: "Blog",
    companyName: "Schnelleintaxi",
    companyAddress: "Houckgeeststraat 13 Eindhoven",
    ourMission: "OUR MISSION",
    kvk: "KVK 65752376",
    missionStatement: `We strive to provide our customers with professional, on-time,
    safe and comfortable taxi transportation and creating business opportunities for our drivers.`,
  },
  paymentScreen: {
    buttonText: "Pay",
  },
  airportPage: {
    taxiTo: "TAXI TO",
    airport: "AIRPORT",
    logoText: `Need a taxi to `,
    logoText2:
      ". At taxi spot you can book a taxi online, easily, safely and cheaply!",
    firstPara: `Are you planning to fly via `,
    firstPara1: `? And do you need transportation?
    There are several ways to travel to `,
    firstPara2: `. You can drive your car to the airport,
    use public transport, have someone take you away or opt for an airport taxi.
    Travelling by taxi is comfortable and fast, in addition you don't have to
    charge for parking at the airport and also not to walk far with bags and/or suitcases.
    Don't care if you're alone, with your family or other acquaintances.
    You don't have to worry, we've got you covered. Our drivers will provide
    you with a pleasant ride to `,
    firstPara3: `. This in combination with the
    skills of a professional taxi driver who ensures that you arrive at `,
    firstPara4: ` on time every time.`,
    firstQuestion: `Have a Questions?`,
    firstHeading: `How can I book a taxi to `,
    para2: `With us you can easily book a taxi to`,
    step1: `Step 1: Click Book your taxi`,
    step2: `Step 2: Give the address from where you want to travel to `,
    step3: `Step 3: Choose the vehicle that suits you best?`,
    firstPoint: `Travel with max. 4 men to`,
    firstPoint1: `airport and little luggage then it is best
    to choose a standard car!`,
    secondPoint: `If you travel with 8 people to`,
    secondPoint1: `,  choose a minivan!`,
    step4: `Step 4: Enter your personal data and pay securely online.`,
    step5: `Step 5: Enjoy a pleasant and enjoyable ride.`,
    secondQuestion: `What is the price to `,
    secondPara: `When booking your taxi through the site, it will be automatically
    shown what the cost of the taxi ride will be.
    You enter your exact address with which our site calculates a precise price for you.
    You can pay online or with cash in the taxi. This price depends ofcourse on your exact location.
    If you are further away from Schiphol cost will be more than if you are closer of course. `,
    btnText: `Calculate your fare`,
    inGermany: `In Germany`,
    netherlandAirportCard1: `Our most popular airport in the Netherlands is of course`,
    netherlandAirportCardRest: `but apart from that we also transport passengers almost daily to:`,
    netherlandAirportCard2: `In addition to in the Netherlands, we also transport passengers
    to Belgium airport:`,
    fixedPriceQuestion: "Whats the process?",
    fixedpriceHeading: "FIXED PRICES FOR MAX 4,",
    fixedpriceHeading2: "PEOPLE TO ",
    fixedpriceHeading3: " AIRPORT",
    fixedpricePara: "Fixed prices for max 4 people to ",
    fixedpricePara2: " airpot",
    fromText: "Of",
    priceText: "Price",
  },
  twoCities: {
    taxiTo: "TO",
    taxiFrom: "TAXI FROM",
    preHeading: "Have a Question?",
    heading1: "Why book at Schnelleintaxi??",
    firstPara:
      "Ordering a taxi via Schnelleintaxi  guarantees the following things:",
    point1: "A professional driver who is always there for you",
    point2: "An excellent 24/7 service, to reach any time of the day",
    point3: "An incredibly low rate, sometimes with discounts of up to 50%.",
    paraRest: `It is possible to engage us for regular transport,
    however it is also possible to order a taxi for all your trips to and from the airport.
    Schnelleintaxi's taxis will always get you to the gate in time.
    In addition, our experienced and specialized drivers always help you load the luggage.`,
    para2: `Quality, convenience, excellent service for a low price.
    That's exactly what we at Schnelleintaxi  stand for.
    Make an appointment today or book a taxi,
    before you know it you are already at the destination!`,
    btnTxt: "Calculate your Fare!",
    heading2: "How much does a taxi from ",
    headingPart2: " to ",
    headingPart3: " cost?",
    para3: `The cost of a taxi ride depends on: the starting rate, the distance and the duration of
    the ride. This rate prevents you as a customer from having to pay too much.
    This maximum rate is determined by the government. A taxi company may not
    drive higher than the maximum fare. When booking your taxi through the site,
    it will be automatically announced what the cost of the taxi ride will be.
    You enter your exact address with which our site calculates a precise price for you.
    This price depends of course on your exact location. The longer the distance
    the more discount you get, discounts can be up to 50%. If it is a short
    distance you never pay more than the maximum rate. Would you like to know more
    about taxi fares? Take a look at
    our this page `,
    paymentsubheading: "Something to Share!",
    paymentheading: "Payment methods",
    paymentpara: `At Schnelleintaxi  you pay how you like best.
    You can easily and securely pay online in advance with an additional 5% discount.
    Online you can pay by credit card, iDeal,PayPal and Apple Pay.
    If you choose to pay online during the order process
    you will receive an additional 5% discount on your ride.`,
    processHeading: "Whats the process?",
    fixedPriceHeading: "OUR FIXED PRICES TO ",
    fixedPriceFrom: "From ",
    fixedPricePara: ` we have fixed prices for popular destinations that are maintained.
    In the table below you will find prices to the popular destinations.`,
    fromText: "From",
    priceText: "Price",
    costEstimate: {
      headingFirstPart: `How do I book a taxi to `,
      paraText: `With us you can easily book a taxi to `,
      paraText2: ` .`,
      firstPoint: `Step 1: Click Book your taxi`,
      secondPoint: `Step 2: Give the address from where you want to travel to `,
      secondPoint2: `.`,
      thirdPoint: `Step 3: Choose the vehicle that suits you best?`,
      secondPara: `Are you travel with max. 4 persons to `,
      secondPara2: ` and little luggage
			then it is best to choose a standard car!
      If you travel to `,
      secondPara3: ` with 8 persons, choose a minivan!`,
      fourthPoint: `Step 4: Enter your personal data and pay securely online.`,
      fifthPoint: `Step 5: Enjoy a pleasant and enjoyable ride.`,
      buttonText: `Book your taxi`,
      generalHeading: "General information Neumünster",
      generalText: `Neumünster is an independent city in Schleswig-Holstein. The town
      has 79,487 inhabitants on an area of ​​71.63 km2. Neumünster is
      the fourth largest city in the country after Kiel, Lübeck and Flensburg.`,
    },
    moreDiscussion: "More to discuss.",
    regularCust: "Regular customers",
    regularCustPara: `We appreciate that you choose us, which is why we give our regular customers
    10% discount on already low prices.
    Do you travel more than 4times a week?
    Please contact us to be eligible for our regular customers program.`,
    businessTpt: "Business transport from ",
    businessTpt2: " to ",
    regularCustPara2: `For all your business transport you can of course also go to Schnelleintaxi.
    With a private driver you can focus in the car on things other than the road.
    Do you need relaxation during your trip?
    Schnelleintaxi is happy to take away traffic responsibility.
    Thanks to our private drivers, you can focus on business tasks or relaxation during your trip.`,
    bullet1: "Always a certified private driver",
    bullet2: "Representative private drivers",
    bullet3: "Attractive rates",
    bullet4: "Lots of comfort",
    driverHeading: "Our Drivers",
    driverPara: `We have well-trained and qualified drivers for companies and individuals.
    All our drivers are certified and trained in dealing with unexpected situations.
    All our drivers have a basic first aid certificate.`,
    popularCities: "Our Popular Cities",
    popularCitiesPara: `We transport you everywhere from Neumünster to the Netherlands, Belgium and Germany.
    And from every city to Neumünster.`,
    popluarinNl: `Our popular cities in the Netherlands`,
    popularInBelgium: "Popular cities in Belgium",
    popularInDe: "Popular cities in Germany",
    socialMedia: "Join us on social media",
    socialMediaPara: "FOLLOW US ON SOCIAL MEDIA",
  },
  bookingSteps: {
    back1: "Return to Location",
    next1: "Select",
    back2: "Return to Select Car",
  },
  bookingBenefits: {
    benefit1: "Corona proof",
    benefit2: "Track driver",
    benefit3: "Free cancelation and changes",
    benefit4: "Private driver",
  },
  tracking: {
    errorText: "Something went wrong",
    driverArrived: "Driver has been Arrived",
    rideCancelled: "Ride has been Cancelled",
    rideNotStarted: "Ride not Started Yet",
    rideCompleted: "Ride has been Completed",
    pickUp: "From",
    drop: "To",
    dateTimeChange: "Change Date Time",
    payment: "Payment",
    proceed: "Proceed",
    cancelAlert: "Are you sure you want to cancel this ride?",
    cancelRide: "Cancel Ride",
    pickUpDate: "Pickup Date Time",
    shareThisLink: "Share This Tracking Link",
  },
  customerDetail: {
    customer_info: "Customer Details",
    name: "Name",
    email: "Email",
    phone: "Phone No.",
    ride_details: "Ride Details",
    pick_loc: "Pickup Location",
    drop_loc: "Drop Location",
    ttl_fare: "Total Fare",
    pay_mode: "Payment Mode",
    ride_date: "Date",
    driver_earn: "You deserve",
  },
};

export default EN;
